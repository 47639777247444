import React from "react"
import ProfileRouteAnonymous from "../../../../components/auth/ProfileRoute/Anonymous"
import ProposalSingle from "../../../../components/Proposal/ProposalSingle"

const ProposalsSingleAnonymousPage = props => (
  <ProfileRouteAnonymous>
    <ProposalSingle url={props.location.href} />
  </ProfileRouteAnonymous>
)

export default ProposalsSingleAnonymousPage
